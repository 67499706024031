import React from 'react'

import Layout from '../../components/Layout'
import BlogRoll from '../../components/BlogRoll'
import Cover from '../../../static/svg/wood.svg'
import Laptop from '../../../static/img/laptop.png'

export default class BlogIndexPage extends React.Component {
  render() {
    const LaptopImage = (
      <img
        src={Laptop}
        style={{
          height: '100%',
          width: 'auto',
          minWidth: '340px',
        }}
      />
    )

    return (
      <Layout title={'Blog'} description={'Development Blog by Thoaif'}>
        <div
          style={{
            width: '100vw',
            height: '300px',
            backgroundImage: `url(${Cover})`,
            backgroundSize: '100% 100%',
            backgroundRepeat: 'no-repeat',
            position: 'relative',
            paddingTop: '1vh',
            paddingBottom: '4.5vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
          }}
        >
          {LaptopImage}
        </div>
        <section className="section">
          <div className="container">
            <div className="content">
              <BlogRoll />
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}
